import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import * as lib from 'src/javascripts/concerns'

export default class extends Controller {
  static targets = [ 'gen', 'sen', 'calc' ]

  connect() {
  }

  calc() {
    if (!this.genTarget.value) {
      return
    }

    this.senTarget.value = Math.round(this.genTarget.value * 1.1)
  }
}
